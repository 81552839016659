<template>
  <div v-if="products.length > 0" class="products-grid">
    <div
      v-if="title"
      class="products-grid__title"
      :class="$device.isDesktop && 'products-grid__title__has-navigation'"
    >
      <span>{{ title }}</span>
      <GridNavigation
        v-if="$device.isDesktop"
        @changeSlide="(direction) => glideProductsRow.go(direction)"
      />
    </div>
    <div v-show="layout === 'grid'" class="products-grid__grid">
      <div
        :class="`products-grid__${layout}__product`"
        v-for="(product, index) in products"
        :key="`products-grid-${index}`"
      >
        <ProductCard :product="product" />
      </div>
    </div>
    <div
      v-show="layout !== 'grid'"
      class="glide products-grid__row"
      :id="gridId"
    >
      <style v-if="productsGridSliderHeight">
        :root {
          --products-grid-slider-height: {{ `${productsGridSliderHeight}` }};
        }
      </style>
      <div class="glide__track products-grid__row__track" data-glide-el="track">
        <ul class="glide__slides">
          <li
            class="glide__slide"
            v-for="(product, index) in products"
            :key="`products-row-${index}`"
          >
            <ProductCard :product="product" :is-slider="true" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted, defineComponent } from '@nuxtjs/composition-api';
import Glide, { Swipe } from '@glidejs/glide/dist/glide.modular.esm';
import ProductCard from '~/components/General/ProductCard/ProductCard';
import GridNavigation from '~/components/Catalog/GridNavigation';

export default defineComponent({
  name: 'ProductsGrid',
  components: {
    ProductCard,
    GridNavigation,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    products: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    layout: {
      type: String,
      default: 'grid',
      validator: (value) => ['grid', 'row'].includes(value),
    },
    gridId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const glideProductsRow = ref();
    const productsGridSliderHeight = ref();

    const getGlideOptions = () => {
      let glideOptions = null;
      let container = document.querySelector('.products-grid__row__track');
      if (container) {
        productsGridSliderHeight.value = container.offsetHeight;
        let perView = 3;
        if (window.innerWidth < 768) {
          perView = 1;
        } else if (window.innerWidth < 1024) {
          perView = 2;
        }
        const peekAfter = container.offsetWidth / (perView * 2 + 1);
        glideOptions = {
          perView,
          gap: 0,
          rewind: false,
          bound: true,
          peek: {
            before: 0,
            after: peekAfter,
          },
          dragThreshold: 20,
          swipeThreshold: 20,
        };
      }
      return glideOptions;
    };

    const setEqualHeight = () => {
      if (productsGridSliderHeight.value) {
        productsGridSliderHeight.value = 'unset';
      }
      const container = document.querySelector('.products-grid__row');
      if (container) {
        productsGridSliderHeight.value = `${container.offsetHeight}`;
      }
    };

    onMounted(() => {
      if (props.layout === 'row' && props.products.length > 0) {
        let glideContainer = document.querySelector(`#${props.gridId}`);
        const glideOptions = getGlideOptions();
        if (glideContainer && glideOptions) {
          glideProductsRow.value = new Glide(
            `#${props.gridId}`,
            glideOptions
          ).mount({ Swipe });
          setEqualHeight();
          window.addEventListener('resize', () => {
            glideProductsRow.value.update(glideOptions);
            setEqualHeight();
          });
        }
      }
    });

    return {
      productsGridSliderHeight,
      glideProductsRow,
    };
  },
});
</script>

<style lang="scss">
.products-grid {
  position: relative;
  &__title {
    @include mobile-h6;
    margin-bottom: 1.25rem;
    &__has-navigation {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__row {
    &__track {
      overflow: visible;
      .custom-product-card {
        height: 100%;
      }
    }
  }
  .glide__slides {
    .glide__slide {
      height: auto;
      border-right: var(--general-border);
    }
    &:first-child {
      border-left: var(--general-border);
    }
    &:last-child {
      border-right: var(--general-border);
    }
  }
  @include from-desktop-min {
    &__grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
    }
    &__title {
      @include desktop-h6;
    }
    .glide__slides {
      .glide__slide {
        border: none;
      }
    }
  }
}
</style>
