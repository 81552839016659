<template>
  <div class="products-grid__title__navigation">
    <span> <ArrowPrev @click="$emit('changeSlide', '<')" /> </span>
    <span> <ArrowNext @click="$emit('changeSlide', '>')" /> </span>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { ArrowNext, ArrowPrev } from '~/components/General/Icons';

export default defineComponent({
  name: 'GridNavigation',
  components: {
    ArrowNext,
    ArrowPrev,
  },
  props: {},
  setup() {},
});
</script>

<style lang="scss" scoped>
.products-grid__title__navigation {
  @include pointer;
  user-select: none;
}
</style>
