<template>
  <SfFooter :column="3" multiple class="footer">
    <SfFooterColumn class="footer-first-column">
      <Certification
        v-if="footerContent && footerContent.active"
        :data="footerContent.data"
      />
    </SfFooterColumn>
    <SfFooterColumn class="footer-second-column">
      <UsefulInformations />
    </SfFooterColumn>
    <SfFooterColumn class="footer-newsletter-column">
      <NewsletterSubscription
        v-if="newsletterFooterContent && newsletterFooterContent.active"
        :data="newsletterFooterContent.data"
      />
    </SfFooterColumn>
    <FooterCopyright />
  </SfFooter>
</template>

<script>
import {
  ref,
  useFetch,
  useContext,
  defineComponent,
} from '@nuxtjs/composition-api';
import { SfFooter } from '@storefront-ui/vue';
import Certification from './Footer/Certification.vue';
import UsefulInformations from './Footer/UsefulInformations.vue';
import NewsletterSubscription from './Footer/NewsletterSubscription.vue';
import FooterCopyright from './Footer/FooterCopyright.vue';
import { useContent } from '@gemini-vsf/composables';
import { contentGetters } from '~/composables';

export default defineComponent({
  name: 'AppFooter',
  components: {
    SfFooter,
    Certification,
    UsefulInformations,
    NewsletterSubscription,
    FooterCopyright,
  },
  setup() {
    const {
      app: {
        i18n: {
          localeProperties: { localeValue },
        },
      },
    } = useContext();
    const { blocks, loadBlocks } = useContent(`app-footer-${localeValue}`);

    const footerSelector = `footer-${localeValue}`;
    const footerContent = ref();

    const newsletterFooterSelector = `newsletter-footer-${localeValue}`;
    const newsletterFooterContent = ref();

    useFetch(async () => {
      await loadBlocks({
        identifiers: [footerSelector, newsletterFooterSelector],
      });
      const blockData = await contentGetters.getCmsBlockContent(blocks.value);
      footerContent.value = blockData[footerSelector];
      newsletterFooterContent.value = blockData[newsletterFooterSelector];
    });

    return {
      footerContent,
      newsletterFooterContent,
    };
  },
});
</script>

<style lang="scss">
.sf-footer {
  background-color: var(--c-white);
  color: var(--c-black);
  box-sizing: border-box;
  @include for-desktop {
    @include desktop-boxed;
    margin: 0 auto;
    padding: 0;
  }
  &-column {
    background-color: var(--c-white);
    color: var(--c-text);
    padding: 0;
    margin: 0;
    &__title {
      padding: 0;
      background-color: var(--c-white);
      --heading-title-color: var(--c-text);
      color: var(--c-text);
      button {
        display: none;
      }
    }
    &__content {
      --footer-column-content-display: block;
    }
  }
}
.footer {
  border-top: 0.0625rem solid #000000;
  &-first-column,
  &-second-column {
    border-bottom: 0.0625rem solid #000000;
    @include for-desktop {
      border-right: 0.0625rem solid #000000;
    }
    button {
      display: none;
    }
    .sf-heading {
      display: none;
    }
  }
  &-first-column {
    @include for-mobile {
      border-bottom: none;
    }
  }
  &-newsletter-column {
    border-bottom: 0.0625rem solid #000000;
    @include for-desktop {
      padding: 0 2.5rem 6.125rem 2.5rem;
    }
    button {
      display: none;
    }
    .sf-heading {
      display: none;
    }
  }
}
</style>
