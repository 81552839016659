<template>
  <div class="cart-item">
    <nuxt-img class="cart-item__image" :src="image" />
    <div class="cart-item__details">
      <div class="cart-item__details__header">
        <div class="cart-item__details__header__category" v-if="firstCategory">
          {{ firstCategory }}
        </div>
        <div class="cart-item__details__header__name">{{ cartItemName }}</div>
      </div>

      <div class="cart-item-details__body">
        <div
          class="cart-item__details__body__option"
          v-for="(option, index) in configuredOptions"
          :key="`${cartItem.uid}-option-${index}`"
        >
          <span class="cart-item__details__body__option__label">
            {{
              `${
                translateAttributeCode(option.option_label) ||
                option.option_label
              } :`
            }}
          </span>
          <span class="cart-item__details__body__option__value">
            {{ option.value_label }}
          </span>
        </div>
      </div>

      <div class="cart-item__price">
        <span
          class="cart-item__price__regular"
          v-if="!hasSpecialPrice && finalPrice < regularPrice"
        >
          {{ $fc(regularPrice) }}
        </span>
        <span class="cart-item__price__final">
          {{ $fc(finalPrice) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@nuxtjs/composition-api';
import dataManipulation from '~/helpers/dataManipulation';
import { useAttributeCodeTranslation } from '~/composables';

export default defineComponent({
  name: 'CartItem',
  components: {},
  props: {
    cartItem: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const { arrayIsValidAndHasItems } = dataManipulation();
    const { translateAttributeCode } = useAttributeCodeTranslation();

    const cartItemName = computed(() => props.cartItem?.product?.name);
    const configuredOptions = computed(() =>
      props.cartItem?.configurable_options?.filter(
        (o) => !['sdc_size', 'sdc_lunghezza_catena'].includes(o.option_label)
      )
    );

    const firstCategory = computed(
      () =>
        (arrayIsValidAndHasItems(props.cartItem?.product?.categories) &&
          props.cartItem?.product?.categories[0].name) ||
        null
    );

    const finalPrice = computed(
      () => props.cartItem?.prices?.row_total_including_tax?.value
    );

    const regularPrice = computed(
      () => props.cartItem?.prices?.regular_price?.value
    );

    const hasSpecialPrice = computed(
      () => finalPrice.value < regularPrice.value
    );

    const image = computed(() =>
      props.cartItem?.product?.thumbnail?.url
        ? `${props.cartItem?.product?.thumbnail?.url}?w=300`
        : 'logo/logo-black.svg'
    );

    return {
      cartItemName,
      firstCategory,
      finalPrice,
      regularPrice,
      hasSpecialPrice,
      image,
      configuredOptions,
      translateAttributeCode,
    };
  },
});
</script>

<style lang="scss">
.cart-item {
  display: flex;
  gap: 1.25rem;
  width: 100%;
  padding: 1.25rem 0;
  border-top: 0.0625rem solid var(--c-dark-grey);
  &:last-of-type {
    border-bottom: 0.0625rem solid var(--c-dark-grey);
  }
  &__image {
    width: 38.55%;
    aspect-ratio: 1/1;
    object-fit: contain;
  }
  &__details {
    @include flexbox(
      $direction: column,
      $gap: 10px,
      $justifyContent: space-between
    );
    &__header {
      &__category {
        @include paragraph-s;
        color: #808080;
      }
      &__name {
        @include desktop-h7;
      }
    }
    &__body {
      @include flexbox($direction: column, $gap: 4px);
      &__option {
        &__label,
        &__value {
          @include paragraph-s;
        }
        &__value {
          color: black;
        }
        &__label {
          color: #808080;
        }
      }
    }
  }
  &__price {
    @include paragraph-s;
    @include flexbox($gap: 4px);
    &__regular {
      text-decoration: line-through;
      color: var(--c-dark-grey);
    }
  }
}
</style>
