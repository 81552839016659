<template>
  <section
    class="mini-cart-sidebar__summary"
    :class="{ 'checkout-summary': page === 'checkout' }"
  >
    <div
      v-if="hasFreeShipping"
      class="mini-cart-sidebar__summary__free-shipping"
    >
      {{ $t('Free shipping') }}
    </div>
    <div
      v-for="(summaryRow, index) in summaryRows"
      :key="`mini-cart-summary-row-${index}`"
      class="mini-cart-sidebar__summary__rows"
      :class="`mini-cart-sidebar__summary__${summaryRow.code}`"
    >
      <span class="mini-cart-sidebar__summary__label">
        {{ summaryRow.label }}
      </span>
      <span class="mini-cart-sidebar__summary__value">
        {{ $fc(summaryRow.value) }}
      </span>
    </div>
    <div v-if="page === 'cart'" class="mini-cart-sidebar__summary__ctas">
      <CustomCta
        theme="dark"
        :is-full-width="true"
        :link="localePath('/checkout/user-account')"
      >
        {{ $t('proceed to checkout') }}
      </CustomCta>
      <CustomCta :is-full-width="true" :link="localePath('/cart')">
        {{ $t('view in cart') }}
      </CustomCta>
    </div>
  </section>
</template>

<script>
import { defineComponent, computed, useRoute } from '@nuxtjs/composition-api';
import { useI18n } from '~/helpers/hooks/usei18n';
import { CustomCta } from '~/components/General/';
import { useMarkets } from '~/composables';

export default defineComponent({
  name: 'CartSummary',
  components: {
    CustomCta,
  },
  props: {
    cart: {
      type: Object,
      required: true,
    },
    page: {
      type: String,
      default: 'cart',
      validator(value) {
        return ['cart', 'checkout'].includes(value);
      },
    },
  },
  setup(props) {
    const trans = useI18n();
    const { isUeMarket } = useMarkets();
    const route = useRoute();
    const hasFreeShipping = computed(() =>
      ['it', 'uk', 'eu'].includes(
        route?.value?.path?.split('/')?.[1]?.split('-')?.[0] || ''
      )
    );

    const orderTotalLabel = computed(() => {
      return isUeMarket.value
        ? trans.t('order total (vat incl.)')
        : trans.t('order total');
    });

    const currentShippingMethodPrice = computed(
      () =>
        props.cart?.shipping_addresses?.[0]?.selected_shipping_method?.amount
          ?.value
    );
    const promos = computed(() => props.cart?.prices?.discounts);

    const summaryRows = computed(() =>
      [
        {
          code: 'subtotal',
          label: trans.t('subtotal'),
          value: props.cart?.prices?.subtotal_including_tax?.value || null,
        },
        currentShippingMethodPrice.value
          ? {
              code: 'shipping',
              label: trans.t('shipping'),
              value: currentShippingMethodPrice.value,
            }
          : null,
        props.page === 'checkout' && promos.value?.length
          ? promos.value.map((promo, index) => ({
              code: `promo-code-${index}`,
              label: `${trans.t('promo')}: ${promo.label}`,
              value: promo?.amount?.value || 0,
            }))
          : null,
        {
          code: 'total',
          label: orderTotalLabel.value,
          value: props.cart?.prices?.grand_total?.value || null,
        },
      ]
        .flat()
        .filter(Boolean)
    );

    return { summaryRows, isUeMarket, hasFreeShipping };
  },
});
</script>

<style lang="scss" scoped>
.mini-cart-sidebar {
  &__summary {
    padding-top: 1.875rem;
    height: 15.375rem;
    &__free-shipping {
      @include desktop-h7;
      text-transform: uppercase;
      color: var(--c-dark-grey);
      padding-bottom: 1.25rem;
    }
    &__rows {
      @include flexbox($justifyContent: space-between, $gap: 12px);
      padding-bottom: 1.25rem;
    }
    &__label,
    &__value {
      @include desktop-h7;
      color: var(--c-dark-grey);
      text-transform: uppercase;
    }
    &__total {
      span {
        color: var(--c-black);
      }
    }
    &__ctas {
      @include flexbox($direction: column, $gap: 10px);
    }
  }
}
.checkout-summary {
  height: auto;
  @include from-desktop-min {
    .mini-cart-sidebar__summary {
      &__rows {
        &:last-child {
          padding-bottom: 0;
        }
      }
    }
  }
}
</style>
